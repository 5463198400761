export default {
    actions: {
        fetchAvatar(ctx) {
            ctx.dispatch('apiGetAvatar', {
                success: (choice) => {
                    ctx.commit('updateAvatarChoice', choice);
                }
            });
        },

        fetchAvatarParts(ctx) {
            ctx.dispatch('apiGetAvatarParts', {
                success: (parts) => {
                    ctx.commit('updateAvatarParts', parts);
                }
            });
        },

        commitAvatarChoice(ctx, cb) {
            ctx.dispatch('apiSetAvatar', {data: {avatar: ctx.getters.getAvatarChoiceId}});
        }
    },

    mutations: {
        updateAvatarParts(state, data) {
            state.avatarParts = data;
        },

        updateAvatarChoice(state, data) {
            for (const code in data) {
                data[code].image = process.env.VUE_APP_API_BASE_URL + data[code].image.replace(/^\//, "");
            }

            state.avatarChoice = data;
        },

        changeAvatarChoice(state, data) {
            state.avatarChoice[data.name].id = data.choice.id;
            state.avatarChoice[data.name].image = data.choice.image;
        }
    },

    state: {
        avatarParts: null,
        avatarChoice: null,
    },

    getters: {
        getAvatarParts(state) {
            return state.avatarParts;
        },

        getAvatarChoice(state) {
            return state.avatarChoice;
        },

        getAvatarChoiceId(state) {
            if (state.avatarChoice !== null) {
                const map = {};
                for (const code in state.avatarChoice) {
                    map[code] = state.avatarChoice[code].id;
                }

                return map;
            }

            return null

        },

        getChosenParts(state) {
            if (state.avatarChoice === null || state.avatarParts === null) {
                return null
            }

            const list = {}

            for (const name in state.avatarChoice) {
                if (name in state.avatarParts) {
                    for (let i = 0; i < state.avatarParts[name]['parts'].length; i++) {
                        if (state.avatarParts[name]['parts'][i].id === state.avatarChoice[name].id) {
                            list[name] = state.avatarParts[name]['parts'][i];
                            break;
                        }
                    }
                }
            }

            return list;
        }
    }
}