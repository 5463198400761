<template>
  <v-img :src="src" :eager="true"/>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "BaseImage",
  props: ['src'],

  methods: {
    ...mapMutations(['incrementPreloaderState', 'decrementPreloaderState'])
  },

  created() {
    // this.incrementPreloaderState();
  }
}
</script>