import library from '@/assets/library/texts';

export default {
    actions: {
        fetchLibrarySection(ctx, section) {
            let sectionSteps = [];
            let texts = library[section];

            for (let key in texts) {
                if (texts.hasOwnProperty(key)) sectionSteps.push(texts[key]);
            }

            ctx.commit('updateSteps', sectionSteps);
        },
    },

    mutations: {
        updateSteps( state, sectionSteps ) {
            state.steps = sectionSteps;
        }
    },

    state: {
        steps: [],
    },

    getters: {
        getSteps( state ) {
            return state.steps;
        }
    }
}