
export default {
    actions: {
        fetchWheelItems(ctx) {
            ctx.dispatch('apiWheelItems', {success: (r) => {
                ctx.commit('updateWheelItems', r);
            }});
        },

        fetchWheelStatus(ctx) {
            ctx.dispatch('apiWheelStatus', {success: (r) => {
                ctx.commit('updateWheelStatus', r.isEnabled);
            }});
        }
    },
    mutations: {
        updateWheelItems( state, responseItems ) {
            const items = [];
            const segments = [];

            responseItems.forEach((item, idx) => {
                items.push({
                    text: item.name,
                    id: item.id
                })

                segments.push({
                    text: (idx + 1).toString(),
                    fillStyle: item.color
                })
            })

            state.wheelItems = items;
            state.wheelSegments = segments;
        },

        updateWheelStatus( state, status ) {
            state.wheelStatus = status;
        }
    },
    state: {
        wheelItems: [],
        wheelSegments: [],

        wheelStatus: false,
    },
    getters: {
        getWheelItems( state ) {
            return state.wheelItems
        },

        getWheelSegments( state ) {
            return state.wheelSegments;
        },

        getWheelStatus( state ) {
            return state.wheelStatus;
        }
    }
}