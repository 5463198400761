<template>
  <v-app>
    <ProfileHeader></ProfileHeader>
    <v-main class="align-center">
      <v-sheet class="page__wrap">
        <div class="page__wrap__container">
          <RegistrationModal></RegistrationModal>
          <MessageModal></MessageModal>
          <ApiPreloader></ApiPreloader>
          <router-view />
        </div>
      </v-sheet>
    </v-main>
  </v-app>
</template>

<script>
import MessageModal from "@/components/modals/MessageModal";
import ProfileHeader from "@/components/global/ProfileHeader";
import ApiPreloader from "@/components/global/ApiPreloader";
import RegistrationModal from "@/components/modals/RegistrationModal";

import { mapGetters } from "vuex";
import MapPage from "@/pages/MapPage";

export default {
  name: 'App',

  data() {
    return {
      registered: null,
    }
  },

  computed: {
    ...mapGetters( ["getApiError"] )
  },

  watch: {
    getApiError: function() {
      if (this.getApiError.redirect !== null) {
        this.$router.push(this.getApiError.redirect);
      }
    }
  },

  components: {
    MapPage,
    MessageModal,
    ProfileHeader,
    ApiPreloader,
    RegistrationModal
  },

  mounted() {
    // if(document.querySelector("#app").requestFullscreen)
    //   document.querySelector("#app").requestFullscreen();
    // else if(document.querySelector("#app").webkitRequestFullScreen)
    //   document.querySelector("#app").webkitRequestFullScreen();


  }
}
</script>
