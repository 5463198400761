<template>
  <div class="avatar-preview" v-if="this.getAvatarChoice !== null" v-show="this.isLoaded">
    <BaseImage
        transition="scroll-y-transition"
        v-for="part in this.getAvatarChoice" :key="part.name"
        :src="part.image"
        :alt="part.name" :style="{ zIndex: part.layer }"
        @load="onPreviewLoad"
    />
  </div>
</template>

<script>
import BaseImage from "@/components/base/BaseImage";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AvatarPreview",
  components: {BaseImage},

  props: {
    update: { type: Boolean, default: false }
  },

  data() {
    return {
      isLoaded: false,
      imageLoading: 9,
    }
  },

  methods: {
    onPreviewLoad() {
      this.imageLoading -= 1;
    },

    ...mapActions(['fetchAvatar'])
  },

  watch: {
    imageLoading(value) {
      if (value < 1) {
        setTimeout(() => { this.isLoaded = true }, 100);
      }
    }
  },

  computed: {
    ...mapGetters(['getAvatarChoice']),
  },

  mounted() {
    if (this.update || this.getAvatarChoice === null) {
      this.fetchAvatar();
    } else {
      this.isLoaded = true;
    }
  }
}
</script>