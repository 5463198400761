<template>
  <v-slide-y-transition>
    <div class="registration modal" v-if="isVisible">
      <div class="modal__wrapper">

        <h3 class="title">Registration</h3>

        <div class="content">
          <p class="content__message">{{ getSteps[currentStep - 1] }}</p>

          <div class="content__step" v-if="currentStep === 2">
            <v-btn-toggle v-model="gender" color="orange" mandatory>
              <v-btn value="male">Мальчик</v-btn>
              <v-btn value="female">Девочка</v-btn>
            </v-btn-toggle>
          </div>

          <div class="content__step" v-if="currentStep === 3">
            <v-text-field
                label="Имя твоего персонажа"
                hint="От 3 до 5 букв"
                v-model="nickname" variant="underlined"
                :rules="rules.nickname"
            >
            </v-text-field>
          </div>
        </div>

        <button
            class="next-button thematic-button"
            :class="{'restricted': false}"
            @click="nextStep"
        >
          <span v-if="currentStep !== getSteps.length">Далее</span>
          <span v-else>ОК</span>
        </button>
      </div>
    </div>
  </v-slide-y-transition>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { apiBaseUrl } from "@/assets/library/config";
import BaseImage from "@/components/base/BaseImage";

export default {
  name: "RegistrationModal",
  components: {BaseImage},
  data() {
    return {
      nickname: "",
      correctNickname: true,

      gender: null,

      apiBaseUrl: apiBaseUrl,
      timestamp: Date.now(),

      currentStep: 1,
      rules: {
        nickname: [
          v => v.length > 2 || 'Имя не может быть короче 3 символов',
          v => v.length < 6 || 'Имя не может быть длинее 5 символов',
        ]
      }
    }
  },

  methods: {
    ...mapActions( ['fetchLibrarySection', 'fetchProfileData', 'apiSetGender', 'apiSetName'] ),
    ...mapMutations(['decrementPreloaderState', 'setProfileStatus']),

    checkStep(step, clickedStatus = false) {
      switch (step) {
        case 2:
          if (this.gender !== null) {
            let isMale = false;
            this.gender === 'male' ? isMale = true : isMale = false;

            this.apiSetGender(isMale);

            return true;
          } else return false;

        case 3:
          if ((this.nickname.length >= 2) && (this.nickname.length <= 5)) {
            if (clickedStatus) {
              this.apiSetName(this.nickname);
            }
            return true;
          }

          else {
            this.correctNickname = false;
            return false;
          }

        case 4: {
          this.setProfileStatus(1);

          this.$router.push("/avatar");

        }
      }
      return true;
    },

    nextStep() {
      if (this.checkStep(this.currentStep, true)) this.currentStep++;
    },

    checkNickname() {
      this.correctNickname = !((this.nickname.length < 2) || (this.nickname.length > 5));
    }
  },

  computed: {
    isVisible() {
      return this.getProfileData.status === 0;
    },

    ...mapGetters( [
      'getSteps',
      'getProfileData'
    ] ),
  },

  mounted() {
    this.fetchLibrarySection("registration")
  }
}
</script>