<template>
  <div
      :class="{ 'preloader_hidden': !getPreloaderState}"
      class="preloader"
  >
    <div class="preloader__circle"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApiPreloader",

  methods: {
  },

  computed: {
    ...mapGetters( [
        'getPreloaderState',
    ] ),
  },
}
</script>