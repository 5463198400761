<template>
  <div
      v-if="isVisible"
      class="modal"
  >
    <div class="container">
      <div class="modal-content">
        <div class="modal-content__header">
          <button
              @click="close()"
              class="icon-button"
          >
            <svg width="32" height="32" aria-hidden="true" role="img">
              <use xlink:href="#close-icon"></use>
            </svg>
          </button>
        </div>
        <div class="modal-content__body">
          <h3>{{ getApiError.title }}</h3>
          <p>{{ getApiError.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters, mapMutations} from "vuex";

export default {
  name: "MessageModal",
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    close() {
      this.removeApiError();

      this.isVisible = false;
      setTimeout(() => {
        this.isVisible = this.getApiError !== null
      }, 500);
    },

    ...mapMutations(['removeApiError'])
  },
  computed: {
    ...mapGetters(['getApiError'])
  }
}
</script>