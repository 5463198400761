<template>
  <v-app-bar rounded="pill" v-if="false">

    <v-app-bar-title v-if="getProfileData" style="line-height: normal !important;" class="align-center">
      <v-avatar size="24" color="grey lighten-1"></v-avatar>
      {{ getProfileData.name }}
    </v-app-bar-title>

    <v-spacer></v-spacer>

    <v-btn>
      <v-avatar size="20" color="green lighten-1"></v-avatar>
      <span class="pl-1">{{ getProfileData.crystals }}</span>

    </v-btn>
    <v-btn>
      <v-avatar size="20" color="yellow lighten-1"></v-avatar>
      <span class="pl-1">{{ getProfileData.coins }}</span>
    </v-btn>

  </v-app-bar>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ProfileHeader",

  methods: {
    ...mapActions( ['fetchProfileData'] ),
    ...mapMutations( ['updateProfileData'] ),
  },

  computed: {
    ...mapGetters( ['getProfileData'] ),
  },

  mounted() {
    this.fetchProfileData();
  }
}
</script>