<template>
  <BaseImage transition="fade-transition" :src="src" :class="name" :alt="name" :aria-label="name" role="img"/>
</template>

<script>
import BaseImage from "@/components/base/BaseImage";
export default {
  name: "MapImage",
  components: {BaseImage},
  props: ['src', 'name']
}
</script>