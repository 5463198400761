<template>
  <a :href="externalUrl" target="_blank">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "BaseExternalLink",
  props: {
    href: String,
  },
  data() {
    return {
      externalUrl: process.env.VUE_APP_PLATFORM_BASE_URL + process.env.VUE_APP_GAME_PATH + "#" + this.href
    }
  }
}
</script>